<template>
  <div>
    <div>
      <div class="right_header">
        <Row :gutter="16">
          <Col span="5">
            <span>价格区间</span>
            <Row :gutter="16">
              <Col span="11">
                <Input v-model="params.minPrice" />
              </Col>
              <Col span="2"
                ><span style="margin-bottom: 0; margin-top: 8px">-</span></Col
              >
              <Col span="11">
                <Input v-model="params.maxPrice" />
              </Col>
            </Row>
          </Col>
          <Col span="3">
            <span>专利类型</span>
            <Select v-model="params.patentType" :transfer="transfer">
              <Option value="">全部</Option>
              <Option value="0">发明</Option>
              <Option value="1">实用</Option>
              <Option value="2">外观</Option>
            </Select>
          </Col>
          <Col span="4">
            <span>法律状态</span>
            <Select v-model="params.patentType" :transfer="transfer">
              <Option value="">全部</Option>
              <Option v-for="(item,index) in lawType" :value="item.lsnt" :key="index">{{item.lsnt}}</Option>
            </Select>
          </Col>
          <Col span="4">
            <span>回复状态</span>
            <Select v-model="params.state" :transfer="transfer">
              <Option value="">全部</Option>
              <Option value="0">未回复</Option>
              <Option value="1">已回复</Option>
            </Select>
          </Col>

          <div style="display: flex; margin-top: 0px">
            <div
              class="button_style"
              style="margin-left: 5px; position: relative; top: 50%"
              @click="search"
            >
              查询
            </div>

            <div
              class="button_style"
              style="position: relative; top: 50%; background-color: #fda736"
              @click="exportDemand"
            >
              导出
            </div>

            <div
              class="button_style"
              style="
                position: relative;
                top: 50%;
                background-color: #33c26c;
                width: 90px;
              "
              @click="addOrder()"
            >
              新增求购专利
            </div>
          </div>
        </Row>
      </div>
      <div class="right_real_content">
        <Table border :columns="columns12" :data="data7" :disabled-hover="true">
          <template slot-scope="{ row }" slot="name">
            <strong>{{ row.name }}</strong>
          </template>
          <template slot-scope="{ row, list }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 5px; color: rgb(54, 194, 207)"
              @click="deleteOrder(row)"
              >删除
            </Button>
            <span class="operation_span" v-if="row.state === '已回复'">|</span>
            <Button
              type="error"
              size="small"
              style="color: rgb(54, 194, 207)"
              @click="seeReplay(row)"
              v-if="row.state === '已回复'"
              >查看回复</Button
            >
          </template>
        </Table>
        <Page
          :total="total"
          show-elevator
          style="margin-top: 50px; float: right"
          @onchange="changePage"
        />
      </div>
    </div>

    <Modal
      id="replayContent"
      v-model="replayModal"
      title="回复内容"
      width="800"
      :footer-hide="footHide"
    >
      <div style="margin-bottom: 50px">
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            {{ replayContent }}
          </div>
        </div>
      </div>
    </Modal>

    <!--删除求购信息-->
    <Modal
      v-model="deleteOrderModal"
      title="删除求购信息"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 30px">
              <span>您确定删除该求购信息？</span>
            </div>
            <div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="deleteOrderModal = false"
              >
                我再想想
              </div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                @click="confirmDelete()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!--新增求购专利-->
    <Modal
      v-model="addOrderModal"
      title="新增出售专利"
      width="800"
      :footer-hide="footHide"
    >
      <div style="margin-bottom: 50px">
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <Form :model="addOrderForm" :label-width="100">
              <FormItem label="法律状态：">
                <CheckboxGroup
                  v-model="addOrderForm.patentStatus"
                  @on-change="dataChange"
                >
                  <Checkbox label="已下证"></Checkbox>
                  <Checkbox label="未缴费"></Checkbox>
                  <Checkbox label="查询中"></Checkbox>
                </CheckboxGroup>
              </FormItem>

              <FormItem label="专利类型：">
                <CheckboxGroup
                  v-model="addOrderForm.patentType"
                  @on-change="dataChange2"
                >
                  <Checkbox label="发明专利"></Checkbox>
                  <Checkbox label="实用新型"></Checkbox>
                  <Checkbox label="外观专利"></Checkbox>
                </CheckboxGroup>
              </FormItem>
              <FormItem label="价格区间：">
                <Row :gutter="16">
                  <Col span="6">
                    <Input
                      v-model="addOrderForm.minPrice"
                      placeholder="最低价"
                    />
                  </Col>
                  <Col span="2"
                    ><span style="margin-bottom: 0; margin-top: 8px"
                      >-</span
                    ></Col
                  >
                  <Col span="6">
                    <Input
                      v-model="addOrderForm.maxPrice"
                      placeholder="最高价"
                    />
                  </Col>
                </Row>
              </FormItem>
              <FormItem label="求购信息：">
                <Input
                  v-model="addOrderForm.demandDesc"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 8 }"
                  placeholder="请输入求购信息"
                ></Input>
              </FormItem>
            </Form>
          </div>
          <div style="margin-left: 50px">
            <div
              class="button_style"
              style="
                margin-left: 26px;
                width: 80px;
                position: relative;
                top: 50%;
              "
              @click="confirmAdd()"
            >
              提交
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getDemandList,
  saveDemand,
  deleteDemand,
  demandExport, getLawType,
} from "../../../plugins/api/patentTrustee";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
    isNumber,
  errorToast,
} from "@/plugins/tools/util";
import axios from "axios";
import { patentUrl } from "../../../plugins/http/baseUrl";
import { getLocalToken } from "../../../plugins/tools/storage";

export default {
  name: "FindPatent",
  data() {
    return {
      lawType:[],
      transfer: true,
      replayModal: false,
      replayContent: "",
      total: 10,
      footHide: false,
      deleteOrderModal: false,
      data7: [],
      columns12: [
        {
          title: "求购信息",
          key: "demandDesc",
          align: "center",
        },
        {
          title: "专利类型",
          key: "patentTypeName",
          align: "center",
        },

        {
          title: "法律状态",
          key: "patentStatusName",
          align: "center",
        },
        {
          title: "价格区间",
          key: "qj",
          align: "center",
        },
        {
          title: "回复状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          width: 250,
          align: "center",
        },
      ],
      params: {
        minPrice: "",
        maxPrice: "",
        patentType: "",
        patentStatus: "",
        state: "",
        pageNum: 1,
        pageSize: 10,
      },
      lawType: [],
      patentType: "",
      priceStart: "",
      priceEnd: "",
      buyPatentType: [],
      buyReplayType: [],
      addOrderForm: {
        patentStatus: [],
        patentType: [],
        minPrice: "",
        maxPrice: "",
        demandDesc: "",
      },
      addOrderModal: false,
      deleteItem: {},
    };
  },
  methods: {
    async exportDemand() {
      let json = await axios({
        method: "post",
        url: `${patentUrl}/patent/demandExport`,
        data: this.params,
        responseType: "arraybuffer",
        headers: { token: getLocalToken() },
      });
      let fileName = "报告.xls";
      if(json.status == 200){
      let blob = new Blob([json.data], {
        type: "application/vnd.ms-excel};charset=utf-8",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
      }else{
        errorToast(json.message)
      }
    },
    // 得到列表
    async getDemand() {
      let json = await getDemandList(this.params);
      if (json.code == 0) {
        this.data7 = json.data.list;
        this.total = json.data.total;
        this.data7.forEach((item, index) => {
          item["qj"] = item["minPrice"] + "-" + item["maxPrice"];
          if (item.state == 0) {
            item.state = "未回复";
          } else {
            item.state = "已回复";
          }
        });
      } else {
        errorToast(json.message);
      }
      this.$parent.changeHeight();
    },

    dataChange(data) {
      if (data.length > 1) {
        this.addOrderForm.patentStatus = [];
        let index = String(data.length - 1);
        this.addOrderForm.patentStatus = [data[index]];
      }
    },
    dataChange2(data) {
      if (data.length > 1) {
        this.addOrderForm.patentType = [];
        let index = String(data.length - 1);
        this.addOrderForm.patentType = [data[index]];
      }
    },
    // 翻页
    changePage(index) {
      this.params.pageNum = index;
      this.getDemand();
    },

    // 查询
    search() {
      this.params.pageNum = 1;
      this.getDemand();
    },
    // 添加
    addOrder() {
      this.addOrderModal = true;
    },
    async confirmAdd() {
      let data = {};
      switch (this.addOrderForm.patentStatus[0]) {
        case "已下证":
          data.patentStatus = 0;
          break;
        case "未缴费":
          data.patentStatus = 1;
          break;
        case "查询中":
          data.patentStatus = 2;
          break;
      }
      switch (this.addOrderForm.patentType[0]) {
        case "发明专利":
          data.patentType = 0;
          break;
        case "实用新型":
          data.patentType = 1;
          break;
        case "外观专利":
          data.patentType = 2;
          break;
      }
      if(!isNumber(this.addOrderForm.minPrice) || !isNumber(this.addOrderForm.maxPrice)){
        warnToast("价格区间请填写数字")
        return ;
      }
      if(Number(this.addOrderForm.minPrice) >  Number(this.addOrderForm.maxPrice)){
        warnToast("最低价不能低于最高价")
        return ;
      }
      data.demandDesc = this.addOrderForm.demandDesc;
      data.minPrice = this.addOrderForm.minPrice;
      data.maxPrice = this.addOrderForm.maxPrice;

      let json = await saveDemand(data);
      if (json.code == 0) {
        this.getDemand();
        this.addOrderModal = false;
        this.addOrderForm.demandDesc ="";
        this.addOrderForm.minPrice =""
        this.addOrderForm.maxPrice =""
        this.addOrderForm.patentType = [];
        this.addOrderForm.patentStatus = [];
      } else {
        errorToast(json.message);
      }
    },

    // 删除
    async confirmDelete() {
      let data = {
        demandId: this.deleteItem.id,
      };
      let json = await deleteDemand(data);
      if (json.code == 0) {
        successToast("删除成功");
        this.getDemand();
        this.deleteOrderModal = false;
      } else {
        errorToast(json.message);
      }
    },
    deleteOrder(row) {
      this.deleteItem = row;

      this.deleteOrderModal = true;
    },

    seeReplay(row) {
      this.replayContent = row.processDesc || "";
      this.replayModal = true;
    },
  },
  mounted() {
    getLawType().then((res) => {
      if(res.code == 200){
        this.lawType = res.data
      }
    })
    this.getDemand();
  },
};
</script>


<style scoped lang="scss">
/deep/ .ivu-select-selection {
  border-radius: 0px;
}

/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
}

/deep/ td {
  border-right: none !important;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  overflow: hidden;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
  border-radius: 2px;
  background-color: rgb(54, 194, 207);
  width: 80px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  padding: 0 5px;
  box-sizing: border-box;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}
</style>
